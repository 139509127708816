






























import { Component, Prop, Vue } from 'vue-property-decorator'
import ReportPage from './ReportPage.vue'
import ValuationFormBlock from './Blocks/ValuationFormBlock.vue'
import EditOnly from './EditOnly.vue'

import Components from './Blocks'
import { VueConstructor } from 'vue'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import { Services } from 'truemarket-modules/src/services'
import IReportDataModel from 'truemarket-modules/src/models/api/reports/IReportDataModel'
import { GetBlockBaseModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import ReportBuilderToolbar from './ReportBuilderToolbar.vue'
import { Modal } from '@/app_code/Modals/Modal'
import { DropdownField } from '@/app_code/Forms'

import CoverPageBlock from './Blocks/CoverPageBlock.vue'
import PropertyAssessmentPageBlock from './Blocks/PropertyAssessmentBlock.vue'
import AssessmentConsiderationsBlock from './Blocks/AssessmentConsiderationsBlock.vue'
import ComparableSalesDetailsBlock from './Blocks/ComparableSalesDetailsBlock.vue'
import AssessmentSummaryBlock from './Blocks/AssessmentSummaryBlock.vue'
import ValuerInsightsBlock from './Blocks/ValuerInsightsBlock.vue'
import DisclaimerBlock from './Blocks/DisclaimerBlock.vue'

Components.push(...[
  ReportPage,
  EditOnly,
  ReportBuilderToolbar,
  ValuationFormBlock
])

function mapComponentArray (components: VueConstructor<Vue>[]) {
  const map: Record<string, VueConstructor<Vue>> = {}

  for (let i = 0; i < components.length; i++) {
    const c = components[i]

    console.log(c)

    map[c.name] = c
  }

  return map
}

@Component({
  // components: mapComponentArray(Components)
  components: {
    ReportPage,
    EditOnly,
    ReportBuilderToolbar,
    ValuationFormBlock,
    CoverPageBlock,
    PropertyAssessmentPageBlock,
    AssessmentConsiderationsBlock,
    ComparableSalesDetailsBlock,
    AssessmentSummaryBlock,
    ValuerInsightsBlock,
    DisclaimerBlock
  }
})
export default class ReportBuilder extends Vue {
  @Prop({ required: true })
  private readonly report!: GetReportModel;

  private reportData: IReportDataModel | null = null

  private blocks: GetBlockBaseModel[] = []

  async mounted () {
    console.log(Components)

    await this.getReportData()
    this.updateBlocks()
  }

  async getReportData (): Promise<void> {
    const data = await Services.API.Reports.GetReportData(this.report.ReportId)
    console.log('--------------------------------------')
    console.log(data.Data)
    this.reportData = data.Data
  }

  updateBlocks () {
    Services.API.Reports.GetBlocks(this.report.ReportId).then((blocks) => {
      this.blocks = blocks
    })
  }

  removeBlock (block: GetBlockBaseModel): void {
    Modal.Confirm({ Content: 'Are you sure you want to delete the selected block?' }).then(() => {
      Services.API.Reports.DeleteBlock(block.BlockId).finally(() => {
        this.updateBlocks()
      })
    })
  }

  addPageAtIndex (idx: number): void {
    const modal = new Modal({
      Heading: 'Add Page',
      FormFields: [
        new DropdownField({
          Label: 'Page Type',
          Key: 'Type',
          Items: [
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.CoverPageBlock',
              Label: 'Cover Page'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.PropertyAssessmentPageBlock',
              Label: 'Property Assessment'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.AssessmentConsiderationsBlock',
              Label: 'Assessment Considerations'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.ComparableSalesDetailsBlock',
              Label: 'Comparable Evidence'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.AssessmentSummaryBlock',
              Label: 'Assessment Summary'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.ValuerInsightsBlock',
              Label: 'Valuer Insights'
            },
            {
              Key: 'TrueMarket.Reports.Builder.Blocks.DisclaimerBlock',
              Label: 'Disclaimer'
            }
          ]
        })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Save',
          OnClick: () => {
            modal.GetFormValues().then((vals) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Services.API.Reports.CreateBlock(this.report.ReportId, idx, vals.Fields.find((f) => f.Key === 'Type')!.Value).then(() => {
                this.updateBlocks()

                modal.Hide()
              })
            })
          }
        }
      ]
    })

    modal.Show()
  }
}
