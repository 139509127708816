






import ReportBuilder from '@/components/Controls/ReportBuilder/ReportBuilder.vue'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ReportBuilder
  }
})
export default class EditReport extends Vue {
  private report: GetReportModel | null = null;

  mounted () {
    const id = this.$route.params.id

    if (id) {
      Services.API.Reports.GetReport(id).then((report) => {
        this.report = report
      })
    }
  }
}
